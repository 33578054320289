<template>
  <div>
    <component
      :is="AdvertiseTee"
    />
    <div class="box" pdt="8xl" pdb="6xl" halign="center">
      <NuxtLink to="/" class="box" :title="$t('go-home')">
        <Image
          :image="globals.getImage(`/base/logo-symbol-primary.webp`)"
          :width="`3rem`"
          :height="`3rem`"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts">
// import AdvertiseTee from '~/component/advertise/tee.vue'

export default {
  setup () {

    // 메모리 비우기
    function restore () {
      AdvertiseTee.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const AdvertiseTee: any = shallowRef(null)
    const loadComponent = async () => {
      const AdvertiseTee_load = await import('~/component/advertise/tee.vue')
      AdvertiseTee.value = AdvertiseTee_load.default || AdvertiseTee_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      // 컴포넌트
      AdvertiseTee
    }
  }
}
</script>